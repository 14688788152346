export default {
    namespaced: true,
    state: {
        common: {},
        comtent: {},
    },
    getters: {
        appData(state) {
            return state.common;
        },
        comtent(state) {
            return state.comtent;
        },
    },
    mutations: {},
    actions: {
        init({ state }, params) {
            state.common = params;
        },
        setComtent({ state }, params) {
            state.comtent = params;
        },
    },
};
