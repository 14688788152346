<template>
    <div id="app" @click="closeNav">
        <home-head ref="homeHead"></home-head>
        <router-view />
    </div>
</template>
<script>
import HomeHead from './components/homeHead.vue';
import { mapActions } from 'vuex';
export default {
    components: { HomeHead },
    created() {
        //获取配置
        this.$api.getInit().then((res) => {
            console.log(res);
            if (res.code == 200) {
                this.init(res.data);
            }
        });
        //获取内容
        this.$api.getContent({ platform: this.isMobile ? 'h5' : 'pc' }).then((res) => {
            if (res.code == 200) {
                this.setComtent(res.data);
            }
        });
    },
    methods: {
        ...mapActions({
            init: 'app/init',
            setComtent: 'app/setComtent',
        }),
        closeNav() {
            const instance = this.$refs.homeHead;
            if (!instance.$refs.nav.classList.contains('active')) return;
            else instance.showNav();
        },
    },
};
</script>
<style lang="scss" scoped></style>
