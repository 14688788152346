<template>
    <header class="header">
        <div class="container">
            <a href="javascript:void(0)" class="inline-block logo" @click="gohome" :style="{ background: 'url(' + logoUrl + ') 0px 0px / 100% no-repeat' }">
                <h1>
                    國際投資、international、investment、國外投資、國內投資、戰略投資、金融服務、Consultation、翡翠珠寶、大健康、按揭借貸、金融、finance、互聯網項目、Internet
                    Project、IPFS、分佈式存儲、挖礦、礦機、FIL幣、投資咨詢、天使投資、資本、融資控股、資產增值、投資方案、投資項目、投資管理、風險分析、risk management、新型投資
                </h1>
            </a>
            <div class="right" ref="nav" @click.stop="showNav()">
                <nav>
                    <ul class="nav">
                        <router-link :class="['nav-item', 'inline-block']" to="/home" active-class="active">{{ $t('m.10001') }}</router-link>
                        <router-link :class="['nav-item', 'inline-block']" to="/project" active-class="active">{{ $t('m.10003') }}</router-link>
                        <router-link :class="['nav-item', 'inline-block']" to="/information" active-class="active">{{ $t('m.10002') }}</router-link>
                        <router-link :class="['nav-item', 'inline-block']" to="/contactus" active-class="active">{{ $t('m.10004') }}</router-link>
                    </ul>
                </nav>
                <div class="langugage">
                    <span :class="['pointer', { active: lang === 'zh_HK' }]" @click="selectLang('zh_HK')">繁</span>
                    <span class="line">/</span>
                    <span :class="['pointer', { active: lang === 'en_US' }]" @click="selectLang('en_US')">EN</span>
                </div>
            </div>
            <div class="right-mobile" @click.stop="showNav" hidden="true"></div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            lang: localStorage.lang || 'zh_HK',
        };
    },
    computed: {
        ...mapGetters({
            appData: 'app/appData',
        }),
        logoUrl() {
            return this.appData.logo && this.appData.logo.content;
        },
    },
    mounted() {},
    methods: {
        selectLang(lang) {
            this.lang = lang;
            localStorage.lang = lang;
            window.location.reload();
        },
        // 侧边栏显示与隐藏
        showNav() {
            this.$refs.nav.classList.toggle('active');
        },
        gohome() {
            if (this.$route.path.includes('home')) window.scrollTo(0, 0);
            else this.$router.push({ name: 'Home' });
        },
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .header {
        height: vv(40);
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2020;
        background-color: #fff;
        box-shadow: 0px 2px vv(15) 0px rgba(153, 153, 153, 0.1);
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            .logo {
                width: vv(59);
                height: vv(23);
                h1 {
                    text-indent: -9999em;
                    overflow: hidden;
                    margin: 0;
                    height: 100%;
                }
            }
            .right {
                position: fixed;
                right: vv(-143);
                top: vv(34);
                background-color: #fff;
                width: vv(143);
                transition: all 0.5s;
                padding: vv(10) 0 vv(25);
                &.active {
                    right: 0;
                }
                .nav {
                    .nav-item {
                        padding: vv(20) 0 vv(20) vv(35);
                        display: block;
                        font-size: vv(14);
                        &.active {
                            color: $b3;
                            font-weight: 600;
                            border-bottom: 0;
                        }
                    }
                }
                .langugage {
                    display: flex;
                    justify-content: center;
                    .line {
                        width: vv(10);
                        text-indent: -9999px;
                        background-color: #fff;
                        border: 0;
                    }
                    span {
                        display: inline-block;
                        text-align: center;
                        width: vv(37);
                        height: vv(23);
                        line-height: vv(22);
                        color: #b2b2b2;
                        border: 1px solid #f1f1f1;
                        background: #f9f9f9;
                        font-size: vv(12);
                        &.active {
                            color: $b6;
                        }
                    }
                }
            }
            .right-mobile {
                display: block;
                width: vv(20);
                height: vv(20);
                background: url(~@/assets/imgs/nav.png) 0 0 no-repeat;
                background-size: 100%;
            }
        }
    }
}

@media (min-width: 769px) {
    .header {
        height: 68px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2020;
        background-color: #fff;
        box-shadow: 0px 2px 30px 0px rgba(153, 153, 153, 0.1);
        display: flex;
        justify-content: center;
        padding: 0 30px;
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .logo {
                width: 118px;
                height: 46px;
                background: url(~@/assets/imgs/logo.png) 0 0 no-repeat;
                background-size: 100%;
                h1 {
                    text-indent: -9999em;
                    overflow: hidden;
                    margin: 0;
                    height: 100%;
                }
            }
            .right {
                display: flex;
                align-items: center;
                .nav {
                    height: 68px;
                    .nav-item {
                        height: 68px;
                        line-height: 68px;
                        color: $b6;
                        font-size: 16px;
                        margin-right: 42px;
                        &:last-child {
                            margin-right: 148px;
                        }
                        &.active {
                            color: $b3;
                            font-weight: 600;
                            border-bottom: 2px solid #009aa4;
                        }
                    }
                }
                .langugage {
                    span {
                        padding: 0 5px;
                        color: #b2b2b2;
                        font-size: 16px;
                        &.active {
                            color: $active;
                            font-weight: 600;
                        }
                    }
                }
            }
            .right-mobile {
                display: none;
            }
        }
    }
}

@media (min-width: 1200px) {
    .header {
        padding: 0;
        .container {
            width: 1200px;
        }
    }
}
</style>
