import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'normalize.css';
import '@/style/iconfont.css';
import '@/style/default.css';

import '@/utils/tools.js';
import '@/utils/element.js';
import VueI18n from 'vue-i18n';

import Mixins from '@/utils/mixin';
Vue.mixin(Mixins);

// 时间格式化
import timeFormat from '@/utils/timeFormat';
Vue.filter('timeFormat', (timestamp, format) => {
    return timeFormat(timestamp, format);
});

//定义接口对象
Vue.prototype.$api = {};

// 系统区分
Vue.prototype.getOS = () => {
    var u = navigator.userAgent;
    if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
        return 'windows';
    } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
        return 'macOS';
    } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
        return 'ios';
    } else if (!!u.match(/android/i)) {
        return 'android';
    } else {
        return 'other';
    }
};
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zh_HK', // 语言标识
    // this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        // 'zh_CN': require('../public/lang/zh'),   // 中文语言包
        en_US: require('./assets/lang/en'), // 英文语言包
        zh_HK: require('./assets/lang/hk'), // 繁体
    },
});

Vue.config.productionTip = false;

const app = new Vue({
    store,
    i18n,
    router,
    render: (h) => h(App),
});

// 导入接口
import Api from '@/api/axios';
Vue.use(Api, app);

app.$mount('#app');
