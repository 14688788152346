export const m = {
    10001: '首頁',
    10002: '企業資訊',
    10003: '投資項目',
    10004: '聯繫我們',
    10005: '前瞻引導 攜手並進 拓展新業務領域',
    10006: 'Prospective guidance Join hands Expand new business area',
    10007: '關於我們',
    10008: '「我們始終致力於利用產業優勢，深度融合產業資源，助力企業走向卓越 」',
    10009: '星河投資有限公司成立於2009年8月27日，以發現並成就偉大企業為使命，致力於做創新價值的“發現者”與“培育者”；',
    10010: '星河投資擁有專業的人才團隊，強大的產業鏈資源、豐富的企業管理、資本運作經驗，深度融合產業資源，助力企業飛速發展，走向卓越，現管理資金總規模超百億人民幣（歷經12年的發展，星河投資項目總資產規模超300億元，淨資產超130億元，品牌價值達500億元.），在國民經濟發展中發揮著投資導向、結構調整和資本經營的獨特作用。',
    10011: '企業願景',
    10012: '「智匯資本、創造價值」',
    10013: '面向未來的星河投資有限公司，將始終堅持「誠信為本、實幹創新 」的企業宗旨，主動服務國家戰略，在重要領域和關鍵行業充分發揮資本引領和帶動作用，大力促進資本佈局和結構優化，建設成為具有全球競爭力的世界一流資本投資公司',
    10014: '企業文化',
    10015: '誠信',
    10016: '我們做出承諾並以身作則',
    10017: '協作',
    10018: '我們尊重工作的多樣性並團結一致',
    10019: '進步',
    10020: '我們在工作中追求卓越',
    10021: '創新',
    10022: '我們尋求創造力並不斷突破',
    10023: '投資領域',
    10024: '星河投資成立以來，不斷完善戰略發展計劃，深度優化資產結構，構建了四大戰略業務單元',
    10025: '基礎產業',
    10026: '重點發展房地產、珠寶、旅遊、能源，交通等產業，以及戰略性稀缺性礦產資源開發業務。',
    10027: '前瞻性產業',
    10028: '推動基金投資與控股投資融合聯動，重點發展先進製造業、IPFS分佈式存儲、大健康、城市環保、生物質能源等產業。',
    10029: '金融及服務業',
    10030: '發展證券、銀行、證券基金、信託、保險、擔保、期貨、財務公司、融資租賃等金融業務，穩妥開展工程設計、資產管理、諮詢、人力資源服務、物業等業務。',
    10031: '國際業務',
    10032: '重點開展境外直接投資、國際工程承包、國際貿易等業務。',
    10033: '企業資訊',
    10034: '查看更多',
    10035: '香港星河信息技術有限公司擬在2021年上線全港最全的本地生活信息服務平台 iHK',
    10036: 'Filecoin官方:星際聯盟宣布投資13億美元建設中國最大的filecoin分佈式儲存產業園',
    10037: '投資界：網路直播市場的火熱發展，珠寶行業將不再局限於實體門店的發展，傳世佳翠珠寶擬在2020年內完成線上數碼化的進程',
    10038: '國融健康旗下品牌 Beyoung 研發成果獲得技術性突破',
    10039: '據星河旅遊第三季度項目計劃中顯示，塞班島超五星旅遊度假村項目啟動順利進行中',
    10045: '聯繫我們',
    10046: '電話',
    10047: '郵箱',
    10048: '地址',
    10049: '香港荃灣海盛路11號One Midtown 9樓901室',
    10050: 'Copyright © 2021 星河信息技術有限公司 版權所有',
    10051: '星河信息技術有限公司',
    10052: 'iHK 香港本地生活信息服務分類平台即將上線',
    10053: '星河國際投資有限公司',
    10054: '囊括能源、日化、房地產開發等多個領域',
    10055: '星河旅遊有限公司',
    10056: '業界有影響力的旅遊度假品牌',
    10057: '傳世佳翠珠寶產業有限公司',
    10058: '專業化、人性化的經營宗旨',
    10059: '鴻盛昌集團有限公司',
    10060: 'NPV達2,400百萬美元',
    10061: '星河按揭財務有限公司',
    10062: '專注以科技優化客戶的貸款體驗',
    10063: '國融健康有限公司',
    10064: '公司產業橫跨了保健品、食品、化妝品、醫藥等',
    10065: '详情',
    10066: '聯繫方式',
    10067: '上一篇',
    10068: '下一篇',

    10118: "請輸入姓名",
    10119: "姓名",
    10120: "電話號碼",
    10121: "请输入電話號碼",
    10122: "请选择",
    10123: "中國香港",
    10124: "中國大陸",
    10125: "中國台灣",
    10126: "中國澳門",
    10127: "公司名稱",
    10128: "請輸入公司名稱",
    10129: "郵箱",
    10130: "請輸入郵箱",
    10131: "備註",
    10132: "簡單描述一下你的需求",
    10133: "提交成功",
    10134: "感謝您的關注，我們將盡快與您聯繫",
    10135: "手機格式不正確",
    10136: "郵箱格式不正確",
    10137: "提交",

    10138: 'iHK生活信息分類平台',
    10139: '星河雲計算技術有限公司',
    10140: 'IPFS分佈式儲存',
    10141: '星河汽車服務有限公司',
    10142: '汽車售後服務',
    10143: '星河置業有限公司',
    10144: '地產服務',
    10145: '礦業',
    10146: '柬埔寨國際投資建設',
    10147: '珠寶產業',
    10148: '大健康產業',
    10149: '旅遊產業',
    10150: '金融按揭服務',
    10151: '星河塞班島旅遊度假村',

}