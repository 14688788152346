import Vue from 'vue';
import VueRouter from 'vue-router';
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const home = () => import('@/views/home/index.vue');
const information = () => import('@/views/information/information.vue');
const contactus = () => import('@/views/contactus/contactus.vue');
const project = () => import('@/views/project/project.vue');
const projectDetail = () => import('@/views/project/projectDetail.vue');
// }
const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'Home',
        component: home,
    },
    {
        path: '/information',
        name: 'Information',
        component: information,
    },
    {
        path: '/contactus',
        name: 'Contactus',
        component: contactus,
    },
    {
        path: '/project',
        name: 'Project',
        component: project,
    },
    {
        path: '/project/detail',
        name: 'ProjectDetail',
        component: projectDetail,
    },
    {
        path: '*',
        redirect: '/home',
    },
];

const router = new VueRouter({
    routes,
});

export default router;
