import Vue from 'vue';
import { Input, Select, Option, OptionGroup, Button, ButtonGroup, Form, FormItem, Breadcrumb, BreadcrumbItem, Icon, Dialog, Row, Col } from 'element-ui';

Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Row);
Vue.use(Col);
