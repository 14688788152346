export const m = {
    10001: 'Home page',
    10002: 'Corporate Information',
    10003: 'Investment project',
    10004: 'Contact us',
    10005: '前瞻引導 攜手並進 拓展新業務領域',
    10006: 'Prospective guidance Join hands Expand new business area',
    10007: 'About us',
    10008: 'We are always committed to making use of industrial advantages, deeply integrating industrial resources and helping enterprises to achieve excellence ',
    10009: 'Starluxe Investment Co., Ltd. was established on August 27, 2009，with the mission of discovering and achieving great companies,committed to being a "discoverer" and "cultivator" of innovative value',
    10010: 'The Starluxe have a professional talent team, powerful industrial chain resources,rich experience in corporate management and capital operation,deep integration of industrial resources,assist the rapid development of the enterprise and move towards excellence.The total scale of funds under management now exceeds RMB 10 billion(After 12 years of development, the total asset scale of the investment project exceeds 30 billion yuan, net assets exceed 13 billion yuan,brand value of 50 billion yuan) playing the unique role of investment orientation、structural Adjustment and capital management in the development of the national economy',
    10011: 'Corporate vision',
    10012: 'Smart Convergence of Capital,Create value',
    10013: 'Facing the future,the Starluxe will always adhere to the corporate tenet of "integrity-based, hard work and innovation", actively serve the national strategy，give full play to the leading and leading role of capital in important fields and key industries,vigorously promote capital layout and structural optimization,to become a world-class capital investment company with global competitiveness.',
    10014: 'Company culture',
    10015: 'Honesty',
    10016: 'We make promises and lead by example',
    10017: 'Cooperation',
    10018: 'We respect the diversity of work and unite as one',
    10019: 'Progress',
    10020: 'We pursue excellence in our work',
    10021: 'Innovation',
    10022: 'We seek creativity and continuous breakthroughs',
    10023: 'Investment areas',
    10024: 'Since the establishment of the company, continuously improve the strategic development plan, deeply optimize asset structure, constructed four strategic business units.',
    10025: 'Basic industry',
    10026: 'Focus on the development of real estate, jewelry, tourism, energy, transportation and other industries and strategic scarce mineral resource development business',
    10027: 'Forward-looking industry',
    10028: 'Promote the integration and linkage of fund investment and holding investment,Focus on the development of advanced manufacturing, InterPlanetary File System, big health, urban environmental protection, biomass energy, and other industries',
    10029: 'Finance and service industry',
    10030: ' Develop financial services such as securities, banks, securities funds, trusts, insurance, guarantees, futures, financial companies, and financial leasing, and steadily carry out engineering design, asset management, consulting, human resources services, property and other businesses',
    10031: 'International Business',
    10032: 'Focus on overseas direct investment, international project contracting, international trade and other businesses',
    10033: 'Corporate Information',
    10034: 'Read more',
    10035: 'Hong Kong Starluxe Information Technology Co., Ltd, who is planned to launch the most complete local life information service platform“iHK” in Hong Kong in 2021.',
    10036: "Filecoin official : The IPFSUNION announced to invest $1.3 billion to build China's largest filecoin distributed storage Industrial Park",
    10037: 'PEdaily：The rapid development of webcast Market，the jewelry industry will no longer be limited to the development of physical stores, The JiaCui Jewelry is planned to complete the process of online digitization in 2020',
    10038: "Grow health's R & D achievements have made a technical breakthrough",
    10039: 'According to the third quarter project plan of Starluxe tourism, Saipan super five star resort project is in smooth progress',
    10045: 'Contact us',
    10046: 'Telephone',
    10047: 'Mailbox',
    10048: 'Address',
    10049: 'RM01,9/F,One Midtown,11 Hoi Shing Road,Tsuen Wan N.T.HongKong',
    10050: 'Copyright © 2021 Starluxe Information Technology Co., Ltd. All rights reserved',
    10051: 'Starluxe Information Technology Company Limited',
    10052: '“ iHK ” HongKong local life information service classification platform will be launched soon',
    10053: 'StarLuxe International Investment Company Limited',
    10054: 'Covers many fields such as energy, daily chemicals, real estate development, etc.',
    10055: 'Statluxe Travel Co., Ltd.',
    10056: 'An influential travel and vacation brand in the industry',
    10057: 'Jia Chui Jewellery Industry Company Limited',
    10058: 'Professional and humanized business purpose',
    10059: 'Hung Shing Cheong Group Company Limited',
    10060: 'NPV reaches USD 2,400 million',
    10061: 'StarLuxe Mortgage Limited',
    10062: "Focus on optimizing customers' loan experience with technology",
    10063: 'Growing Health Company Limited',
    10064: "The company's industry spans health care products, food, cosmetics, medicine, etc.",
    10065: 'Details',
    10066: 'Contact details',
    10067: 'previous',
    10068: 'next',

    10118: "Please type in your name",
    10119: "Name",
    10120: "Tel",
    10121: "Please enter your phone number",
    10122: "please choose",
    10123: "Hong Kong, China",
    10124: "China",
    10125: "Taiwan, China",
    10126: "Macao, China",
    10127: "Company Name",
    10128: "Please enter company name",
    10129: "E-mail",
    10130: "please input your email",
    10131: "Remarks",
    10132: "Briefly describe your needs",
    10133: "Submitted successfully",
    10134: "Thank you for your attention, we will  contact you as soon as possible",
    10135: "Incorrect phone format",
    10136: "E-mail format is incorrect",
    10137: "submit",

    10138: 'iHK life Information Classification Platform',
    10139: 'StarLuxe Computing Technology Company Limited',
    10140: 'IPFS distributed storage',
    10141: 'StarLuxe Motor Vehicle Services Limited',
    10142: 'The automotive after-sales service',
    10143: 'StarLuxe Motor Vehicle Services Limited',
    10144: 'Real Estate Services',
    10145: 'Mining',
    10146: 'Cambodia International Investment and Construction',
    10147: 'Jewellery industry',
    10148: 'Health industry',
    10149: 'Tourism Industry',
    10150: 'Financial Mortgage Service',
    10151: 'Statluxe Travel Company Limited',
}