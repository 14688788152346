
window.onload = function () {
    // 阻止双击放大
    var lastTouchEnd = 0;
    document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    });
    document.addEventListener('touchend', function (event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, false);

    // 阻止双指放大
    document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
    });
}

/**
 * 动画垂直滚动到页面指定位置
 * @param { Number } currentY 当前位置
 * @param { Number } targetY 目标位置
 */
let needScrollTop, dist, timer
function scrollAnimation(currentY, targetY) {
    // 计算需要移动的距离
    needScrollTop = targetY - currentY
    if (targetY > currentY) dist = Math.ceil(needScrollTop / 80)
    else dist = Math.floor(needScrollTop / 80)
    timer = setInterval(() => {
        // 一次调用滑动帧数，每次调用会不一样
        currentY += dist
        window.scrollTo(0, currentY)
        needScrollTop -= dist
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (Math.abs(needScrollTop) > Math.abs(dist)) {
            window.scrollTo(0, currentY)
        } else {
            window.scrollTo(0, targetY)
            clearInterval(timer)
        }
    }, 4)

}
export {
    scrollAnimation
}
