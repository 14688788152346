import axios from 'axios';
console.log(process.env);
const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, // api base_url
    headers: {
        'content-type': 'application/json',
    },
    // timeout: 9000 // 请求超时时间
});

const install = (Vue, app) => {
    // 请求拦截
    http.interceptors.request.use(
        (config) => {
            //配置语言
            if (typeof config.params != 'undefined') {
                config.params.locale = app.$i18n.locale;
                config.params.source = 3;
            } else {
                config.params = {
                    locale: app.$i18n.locale,
                    source: 3,
                };
            }
            return config;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    // 响应拦截
    http.interceptors.response.use(
        (response) => {
            if (response.status == 200) {
                return response.data || {};
            }
            return Promise.reject(response);
        },
        (err) => {
            return Promise.reject(err); // 返回接口返回的错误信息
        }
    );

    //挂载全局
    Vue.prototype.$axios = http;
    //基本配置
    app.$api.getInit = (params = {}) => http.get('/api/index/init', { params });
    //配置内容
    app.$api.getContent = (params = {}) => http.get('/api/index/content', { params });
    //资讯列表
    app.$api.getArticleList = (params = {}) => http.get('/api/article/index', { params });
    //资讯时间轴
    app.$api.getDatelist = (params = {}) => http.get('/api/article/datelist', { params });
    //联系我们
    app.$api.saveContactus = (params = {}) => http.post('/api/contactus/save', params);
    //单页内容
    app.$api.getPageDetail = (params = {}) => http.get('/api/page/detail', { params });
    //单页列表
    app.$api.getPageIndex = (params = {}) => http.get('/api/page/index', { params });
    //项目列表
    app.$api.getInvestIndex = (params = {}) => http.get('/api/invest/index', { params });
    //项目详情
    app.$api.getInvestDetail = (params = {}) => http.get('/api/invest/detail', { params });
};

export default { install };
